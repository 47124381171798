*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul,ol{
    list-style: none;
}

a{
    text-decoration: none;
}

.container{
    width: 1140px;
    margin: 0 auto;
}

