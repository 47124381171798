
.header .container, .header-left, .header-right,.header-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header{
    padding: 15px 0;
}

.menu-btn{
    border: none;
    background-color: transparent;
    font-size: 25px;
    width: 25px;
    height: 25px;
}

.header-link, .logo-link{
    font-size: 18px;
    line-height: 35px;
    transition: 
    0.3s;
    padding:10px 15px;
    
    display: inline-block;
}

 
.header-item{
    padding: 6px;
    margin: 0px 15px;
    color: blueviolet;

}

.logo-link{
    font-size: 30px;
    padding-left: 15px;
    color: blueviolet;
    font-weight: bolder;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 2px;
}

.menu-btn{
    display: none;
}


.header-list{
    padding-left: 50px;
}



.header-link:hover{
    background-color: blueviolet;
    color: white;
    transform: scale(0.90);
    
}

.login-btn, .basket-btn{
    border: none;
    background-color: transparent;
    padding: 10px 25px;
    margin: 0px 15px;
}

.login-btn{
    background-color: blueviolet;
    color: white;
    /* border-radius: 5px; */
    transition: 0.3s;
    display: inline-block;
    font-size: 18px;
    border: 2px solid blueviolet;
}

.login-btn:hover{
    background-color: white;
    color: blueviolet;
    border: 2px solid blueviolet;
    transform: scale(0.9);
    cursor: pointer;
}

.basket-btn{
    border: none;
    background-color: transparent;
    font-size: 20px;
    width: 20px;
    padding-right: 55px;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
}

.basket-btn:hover{
    transform: scale(1.5);
}

.basket-btn p{
    position: absolute;
    top: 0;
    left: 27%;
    transform: translate(90%,20%);
    font-size: 10px;
    color: white;
    background-color: red;
    padding: 8px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;

    justify-content: center;
    align-items: center;
}

 .header-item--active{
    background-color: blueviolet;
    color: white;
    
    transform: scale(0.9);
 }


.header{
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: white;
    padding-top: 6px;
    padding-bottom: 6px;
    top: 0%;
    
}





.modal-box{
    padding: 10px;
}

.modal-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column; */
    margin: 8px 0;
    padding: 4px 0;
    
}
.modal-img{
    width: 80px;
    height: 80px;
}


.modal{
    position: relative;
}
.close-btn{
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translateX(-100%);
    background-color: red;
    color: white;
    border: none;
    font-weight: bolder;font-size: 22px;
    width: 24px;
    height: 24px;
}


::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
  .modal-list{
    overflow: auto;
    height: 340px;
    padding: 0px 5px;
    padding-right: 15px;
}

.modal-title{
    width: 210px;
    white-space: wrap; 
  
  overflow: hidden;
  text-overflow: ellipsis; 
  max-height: 40px;
  
}
.modal-count{
    width: 20px;
}
.modal-price{
    width: 100px;
    color: #51267d;
    
}
.modal-btn{
    width: 20px;
}
.modal-blok{
    width: 90px;
}
.modal-minus, .modal-plus, .modal-count{
    padding: 5px;
    width: 34px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #745298;
}
.modal-item, .modal-blok{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-minus, .modal-plus{
    background-color: rgba(81,38,125,.08);
    color: #51267d;
    cursor: pointer;
    border: none;
    padding: 8px;
    border-radius: 5px;
    font-size: 20px;
}

.modal-btn{
    color: red;
    background-color: white;
    border: none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
}
.modal-context{
    font-size: 35px;
    color: #51267d;
    font-weight: bolder;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
@media(max-width:1160px){
    .container{
        width: 1000px;
    }



}
@media(max-width:1020px){
    .container{
        width: 900px;
    }
    
    .header-item{
        margin: 0px 6px;
    }
}
@media(max-width:920px){
    .container{
        width: 800px;
    }

  .header-list{
      padding-left: 10px;
  }
  .header-item{
    margin: 0px 2px;
}

.header-link{
    padding:10px;
}
    
}

@media(max-width:820px){
    .container{
        width: 700px;
    }
    .basket-btn{
        padding-right: 10px;
    }
    .basket-btn p{
        left: 100%;
        transform: translateY(10%);
    }
  .header-list{
      padding: 0px;
  }
    .header-link, .login-btn{
        font-size: 16px;
    }

    
}
@media(max-width:720px){
    .container{
        width: 600px;
    }

    .header-list{
        display: none;

    }
    .menu-btn{
        display: block;
    }
    
}
@media(max-width:620px){
    .container{
        width: 500px;
    }
    
}

@media(max-width:520px){
    .container{
        width: 400px;
    }
    
}
@media(max-width:420px){
    .container{
        width: 300px;
    }
    .login-btn{
padding: 10px 15px;
    }
  
}
@media(max-width:300px){
    .container{
        width: 90%;
    }
    
}
