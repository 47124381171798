.nested-links{
    display: flex;
justify-content: 
space-between;
align-items: center;

color: white;

}

.nested-links__link{
    color: white;
    padding: 10px 15px;
    transition: 0.3s;
    display: inline-block;
}
.nested-links__link:hover{
    background-color: white;
    color: blueviolet;
    transform: scale(0.9);
}


.category{
    background-color: blueviolet;
    padding: 10px ;
    margin: 25px 0;
}