.logo-links {
    font-size: 30px;

    color: white;
    font-weight: bolder;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 2px;
}


.footer-top,
.social-list,
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer {
    background-color: blueviolet;
    padding: 50px 0;
    color: white;
}


.footer-link {
    color: white;
    display: inline-block;
}

.footer-item {
    padding: 10px;
    padding-left: 0;
}

.footer-title,
.logo-links {
    margin-bottom: 15px;
}


.footer-link:hover {
    color: black;

}

.social-list {
    justify-content: flex-start;
}

.social-link {
    color: white;
    padding: 6px 10px;
    margin: 0px 15px;
    transition: 0.3s;
    border: 1px solid white;
    /* border-radius: 50%; */
    width: 20px;
    height: 20px;

}


.social-link:hover {
    border: 1px solid blueviolet;
    color: blueviolet;
    background-color: white;
    transform: scale(0.5);
}

.footer-bottom {
    border-top: 2px solid white;
    padding-top: 30px;
}

.footer-top {
    padding-bottom: 20px;
}

.developed-link {
    color: white;
}



@media(max-width:1160px){
    .container{
        width: 1000px;
    }
}

@media(max-width:1020px){
    .container{
        width: 900px;
    }
}

@media(max-width:920px){
    .container{
        width: 800px;
    }
}
@media(max-width:820px){
    .container{
        width: 700px;
    }
}

@media(max-width:720px){
    .container{
        width: 600px;
    }

    .footer-title{
        font-size: 20px;
        line-height: 40px;
    }
.footer-top{

    flex-wrap: wrap;
}


.footer-list{
    width: 45%;
}


.footer-list:nth-child(3) .footer-title {
    padding-bottom: 0;
    margin-bottom: 0;
}

    .footer-bottom{
        display: flex;
        flex-direction: column;
    }
}
@media(max-width:620px){
    .container{
        width: 500px;
    }

    .footer .footer-title{
        font-size: 19px;
    }
}
@media(max-width:520px){
    .container{
        width: 400px;
    }
    .footer .footer-title{
    line-height: 30px;
    }
}
@media(max-width:420px){
    .container{
        width: 300px;
    }

    .footer-list:nth-child(3){
        display: block;
    }
    .footer-top, .footer-bottom{
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .footer-list{
        padding: 10px 0;
    }
    .footer-list{
        width: 100%;
    }
    .social-list{
        text-align: center;
        justify-content: center;
    }

    .developed-link{
        text-align: center
        ;
    }
}
@media(max-width:320px){
    .container{
        width: 90%;
    }
}
