.filial-cards{
    padding-top: 100px;
    padding-bottom: 50px;
    margin-top: 50px;
    background-color: #f6f6f6;
}


.filial-box{
    display: flex;
    padding: 20px;
    
    background-color: white;
    border-radius: 12px;
}

 .filial-right{
    width: 60%;
    padding: 0px 15px 0px 20px;
    
}
.filial-left{
    width: 40%;
    padding: 0px 15px;
    
}

.filial-thumb{
    width: 100%;
    border-radius: 12px;
    height: 200px;
    padding-top: 8px;

}

.filial-iframe{
    width: 100%;
    height: 455px;
    border-radius: 12px;
    border: none
        ;
}

.filial-name{
    font-size: 
    32px;
    font-weight: 600;
    line-height: 39px;
    padding-bottom: 20px;
}
.filial-map::before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url("../../Assets/Img/map.png");
    background-repeat: no-repeat;
    background-size: 15px;
    padding-right: 15px;
}
.filial-time::before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 16px;
    background-image: url("../../Assets/Img/clock.png");
    background-repeat: no-repeat;
    background-size: 16px;
    padding-right: 15px;
}

.filial-span::before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 16px;
    background-image: url("../../Assets/Img/call.png");
    background-repeat: no-repeat;
    background-size: 16px;
    padding-right: 15px;
}
.filial-orientr::before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 16px;
    background-image: url("../../Assets/Img/loc.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    padding-right: 15px;
}


.filial-map, .filial-time, .filial-span, .filial-orientr{
    font-size: 18px;
    line-height: 22px;
    margin: 15px 0;
    line-height: 140%;
    
}

.filial-span{
    display: flex;
}

.filial-links{
    color: black;
    padding-left: 20px;
}


@media (max-width:1160px) {
    .container{
        width: 1000px;
    }
    .filial-span{
        font-size: 16px;
    }
}

@media(max-width:1020px){
    .container{
        width: 900px;
    }
    .filial-span a{
        padding-left: 5px;
    }

    .filial-iframe{
        height: 456px;
    }
}

@media(max-width:920px){
    .container{
        width: 800px;
    }

    .filial-box{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .filial-left, .filial-right{
        width: 100%;
        padding: 10px 0;
    }
    .filial-iframe{
        height:300px;
    }
    .filial-span, .filial-span a{
        font-size: 18px;
    }
    
}


@media(max-width:820px){
    .container{
        width: 700px;
    }
}

@media(max-width:720px){
    .container{
        width: 600px;
    }
}

@media(max-width:620px){
    .container{
        width: 500px;
    }
    .filial-iframe{
        height:250px;
    }
}

@media(max-width:520px){
    .container{
        width: 400px;
    }
}


@media(max-width:420px){
    .container{
        width: 300px;
    }

    .filial-name{
        font-size: 28px;
    }
    
.filial-map, .filial-time, .filial-span, .filial-orientr{
    font-size: 15px;   
}
.filial-span, .filial-span a{
    font-size: 15px;
    font-weight: 300;
    
}
.filial-span a{
    padding-left: 0;
}
.filial-name{
    margin-bottom: 0;
    padding-bottom: 0;
}

}


@media(max-width:320px){
    .container{
        width: 90%;
    }
    
.filial-span, .filial-span a{
    font-size: 12px;
    font-weight: 300;
    
}
.filial-span a{
    padding-left: 0;
}
}