img{
    /* width: 50px; */
    z-index:-1;
}

 

.mySwiper2{
    width: 100%;
}

.swiper-img{
    width: 100%;
    height: 450px;
    z-index:-1;
}

.swiper-pic{
    width: 90%;
    height: 150px;
    z-index:-1;
}

.container-fluid{
    width: 100%;
    margin: 0 auto;
}
.swiper{
    margin-top: 40px;
    
    
}
.swipers{
    z-index: -11;
    
}
/* @media(max-width:1550px){
    .container-fluid{
        width: 1400px;
    }
} */
@media(max-width:1420px){
    .container-fluid{
        width: 100%;
    }
}
@media(max-width:1320px){
    .container-fluid{
        width: 100%;
    }
}
@media(max-width:1220px){
    .container-fluid{
        width: 100%;
    }
}
@media(max-width:1160px){
    .container-fluid{
        width: 100%;
    }
}
@media(max-width:1020px){
    .container-fluid{
        width: 100%;
    }
}
@media(max-width:920px){
    .container-fluid{
        width: 100%;
    }
}

@media(max-width:820px){
    .container-fluid{
        width: 100%;
    }
    .swiper-pic{
        width: 100%;
    }
}
@media(max-width:720px){
    .container-fluid{
        width: 100%;
    }

    .mySwiper{
        display:none;
    }

}
@media(max-width:620px){
    .container-fluid{
        width: 95%;
    }
    .swiper-img{
        height: 
        300px;
    }
}
@media(max-width:520px){
    .container-fluid{
        width: 95%;
    }
}
@media(max-width:420px){
    .container-fluid{
        width: 95%;
    }
}
@media(max-width:320px){
    .container-fluid{
        width: 90%;
    }
}